import fetch from '../utils/fetch'

// 获取首页轮播
export function getIndexBanner() {
  return fetch({
    url: '/index/banner',
    method: 'get'
  })
}

// 配置首页轮播
export function editIndexBanner(params) {
  return fetch({
    url: '/index/banner',
    method: 'post',
    params: params
  })
}


// 获取友情链接
export function getIndexLink() {
  return fetch({
    url: '/index/link',
    method: 'get'
  })
}

// 配置友情链接
export function editIndexLink(params) {
  return fetch({
    url: '/index/link',
    method: 'post',
    params: params
  })
}